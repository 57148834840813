@function selfAdapt($px) {
  @return 100vw * ($px / 375);
}

.user {
  background: #ffffff;

  .container {
    padding-bottom: 20px;

    .tap {
      padding: 0 selfAdapt(20);
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      ul {
        display: flex;

        li {
          font-size: selfAdapt(16);
          font-family: PopinsSemi, PingFangSC-Semibold, PingFang SC;
          font-weight: 600;

          a {
            padding-bottom: selfAdapt(8);
            font-size: selfAdapt(14);
            font-family: PopinsSemi, Poppins;
            font-weight: 600;
            color: #2e384e;
            line-height: selfAdapt(25);
            @apply relative;

            .bar {
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 12px;
              height: 3px;
              background: #FF3E5F;
              border-radius: 10px;
            }
          }

        }
      }

      .more {
        width: selfAdapt(52);
        height: selfAdapt(24);
        border-radius: selfAdapt(12);
        text-align: center;
        line-height: selfAdapt(24);
        border: 1px solid #e8e8e8;

        font-size: selfAdapt(10);
        font-family: Popins, Poppins-Regular, Poppins;
        font-weight: 400;
        color: #708096;
      }
    }

    .itemTitle2 {
      padding: 0 0 16px;
      font-size: selfAdapt(10);
      font-family: Popins, Poppins-Regular, Poppins;
      font-weight: 400;
      color: #b8c0cb;
      line-height: selfAdapt(16);
    }

    .item {
      border-bottom: 1px solid #f0f0f0;
      // background: #e6e6e6;
      display: flex;
      align-items: center;
      padding: selfAdapt(10) selfAdapt(5) selfAdapt(10) 0;
      justify-content: space-between;

      .left {
        position: relative;

        .pic {
          width: selfAdapt(48);
          height: selfAdapt(48);
        }

        .duration {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: selfAdapt(3);
          // width: selfAdapt(48);
          height: selfAdapt(12);
          background: linear-gradient(180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.6) 100%);
          border-radius: 0px 0px 4px 4px;
          font-size: selfAdapt(9);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
        }

        .tag {
          border-radius: 50%;
          width: 100%;
          height: 100%;

          display: flex;
          justify-content: center;
          align-items: center;
          font-size: selfAdapt(22);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          background: linear-gradient(118deg, #9b7ad9, #e5405d);
        }
      }

      .center {
        margin-left: selfAdapt(15);
        justify-content: space-between;
        align-items: center;
        flex: 1;

        .title {
          font-size: selfAdapt(14);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #191919;

          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          width: selfAdapt(160);
          height: selfAdapt(20);
        }

        .count {
          font-size: selfAdapt(10);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #708096;
          margin-top: selfAdapt(3);
          max-width: selfAdapt(200);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }

        .down {
          text-align: right;

          .videoCount {
            font-size: selfAdapt(16);
            font-family: Manrope-Bold, Manrope;
            font-weight: bold;
            color: #191919;
          }
        }
      }
    }
  }
}

@media screen and (min-width:1024px) {
  .user {
    display: none;
  }
}
